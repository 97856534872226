/* ======================================================================== */
/* 43. menu */
/* ======================================================================== */
.menu
	position: relative
	+reset-ul
	margin: 0 -15px
	word-wrap: normal
	+small-caps
	> li
		display: inline-block
		&:not(:last-child)
			margin-right: 17px
		a
			display: block
			padding: 0px 15px
	> li
		> a
			padding-top: 10px
			padding-bottom: 10px
	.menu-item-has-children
		position: relative
		> a
			&:hover
				~ ul
					opacity: 1
					visibility: visible
					transform: translate(0px, 0px)
					z-index: 50
.menu.menu_disabled
	.sub-menu
		opacity: 0 !important
		visibility: hidden !important
		transform: translate(0px, 20px) !important
.menu
	.sub-menu
		position: absolute
		top: 100%
		left: 15px
		transform: translate(0px, 20px)
		+reset-ul
		opacity: 0
		visibility: hidden
		+trans1
		z-index: -1
		background-color: #fff
		border-radius: 2px
		text-align: left
		box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.04)
		&:hover
			opacity: 1
			visibility: visible
			transform: translate(0px, 0px)
			z-index: 50
		> li
			white-space: nowrap
			a
				padding: 10px 15px
				background-color: transparent
				border-left: 2px solid transparent
				&:hover
					background-color: #fafafa
					border-color: var(--color-dark-2)
					color: var(--color-dark-2)
		ul
			top: 0
			left: calc(100% + 1px)
			transform: translate(10px, 0px)
			opacity: 0
			visibility: hidden
